import "./App.scss";

// Components
import Footer from "./components/Footer/Footer.jsx";
import Navbar from "./components/Navbar/Navbar.jsx";
import TeamNav from "./components/Navbar_Team/Navbar.jsx";

// Pages
import Errorpage from "./pages/Errorpage/Errorpage.jsx";
import { Switch, Route } from "react-router-dom";
import Team from "./pages/Teampage/Team.jsx";
import FAQ from "./pages/faq/FAQ.jsx";
import Homepage from "./pages/Homepage/Homepage.jsx";
//import Recruitment from "./pages/Recruitment/Recruitment1";
import Gallery from "./pages/Gallery/Gallery.js";
// import Newgallery from "./pages/Gallery/newgallery.js";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path={"/"}>
          <Navbar />
          <Homepage />
        </Route>

        <Route exact path={"/team"}>
          <TeamNav />
          <Team />
        </Route>

        <Route exact path={"/team/:year"} children={<Team />}></Route>
        {/*
        <Route exact path={"/recruitment"}>
        <Navbar />
          <Recruitment />
        </Route> 
          */}

        <Route exact path={"/gallery"}>
        <Navbar />
          <Gallery />
        </Route>

        {/* <Route exact path={"/newgallery"}>
        <TeamNav />
          <Newgallery />
        </Route> */}
        
        <Route exact path={"/faq"}>
          <TeamNav />
          <FAQ />
        </Route>

        <Route exact path={"/*"}>
          <TeamNav />
          <Errorpage />
        </Route>

      </Switch>

      <Footer />
    </div>
  );
}

export default App;
