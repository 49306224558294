import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div>
          <div className="footer-row">
            <div className="footer-coll">
              <h4>Get in Touch</h4>
              <ul>
                <li>
                  <a href="mailto:projectmanas.mit@gmail.com">
                    projectmanas.mit@gmail.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-coll">
              <h4>Phone</h4>
              <ul>
                <li>Gaurav Pradeep ( Technical Head ) : </li>
                <li>+91 9108168772</li>
                <li>Nikillan Rajesh ( Team Manager ) : </li>
                <li>+91 8925511500</li>
                <li> Nirbhay Aher  ( R & D Head ) : </li>
                <li>+91 7977017314 </li>
              </ul>
            </div>
            <div className="footer-coll">
              <h4>Address</h4>
              <ul>
                <li><a href="https://www.google.com/maps/place/Project+MANAS/@13.3475556,74.7896416,17z/data=!3m1!4b1!4m6!3m5!1s0x3bbca4af62b7b18d:0xa84b4ee90a988ae7!8m2!3d13.3475504!4d74.7922165!16s%2Fg%2F11c3ymtqn8?entry=ttu" rel="noreferrer" target="_blank">Automobile Workshop,</a></li>
                <li><a href="https://www.google.com/maps/place/Project+MANAS/@13.3475556,74.7896416,17z/data=!3m1!4b1!4m6!3m5!1s0x3bbca4af62b7b18d:0xa84b4ee90a988ae7!8m2!3d13.3475504!4d74.7922165!16s%2Fg%2F11c3ymtqn8?entry=ttu" rel="noreferrer" target="_blank">Near Kamath Circle,</a></li>
                <li><a href="https://www.google.com/maps/place/Project+MANAS/@13.3475556,74.7896416,17z/data=!3m1!4b1!4m6!3m5!1s0x3bbca4af62b7b18d:0xa84b4ee90a988ae7!8m2!3d13.3475504!4d74.7922165!16s%2Fg%2F11c3ymtqn8?entry=ttu" rel="noreferrer" target="_blank">MIT, Manipal, Karnataka 576104</a></li>
              </ul>
            </div>
            <div className="footer-coll">
              <h4>Connect</h4>
              <div className="social-links">
                <a href="https://www.instagram.com/project.manas/?hl=en" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://www.linkedin.com/company/project-manas/" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
                <a href="https://www.youtube.com/channel/UCpgA3-I00lUVgXVeg1CNaFw" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
                <a href="https://twitter.com/projectmanas" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.facebook.com/projectmanas" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
